import { Box, Checkbox, InputBase, useTheme } from "@mui/material";

interface CheckboxFieldProps {
    value: boolean;
    onChange: (value: boolean) => void;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
    const theme = useTheme();
    const { value, onChange } = props;

    return <Checkbox checked={value} onChange={(event) => onChange(event.currentTarget.checked)} />
        
}