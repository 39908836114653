import { Box, Grid, Stack, Typography } from "@mui/material"
import { ButtonFlatIcon } from "../../components/ButtonFlat";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from "styled-components";

import HexagonIcon from '@mui/icons-material/Hexagon';
import RightIcon from '@mui/icons-material/ArrowRight';
import { ReactNode } from "react";

const Hexagon = styled.div`
    width: 80px;
    height: 80px;
    margin: 0 auto;
    position: relative;
`

const HexagonNumber = styled.div`
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff
`;

const SideLabel = styled.div({
    textAlign: "center",
    fontWeight: 700
});

interface DetailValue {
    value: number;
    label: string;
    customRender: (value: number) => ReactNode;
}

interface DetailPairProps {
    leftValue: number;
    leftCustomDisplay?: (value: number) => string;
    leftLabel: string;
    leftValues: DetailValue[];
    convertCost: number;
    rightValue: number;
    rightCustomDisplay?: (value: number) => string;
    rightLabel: string;
    rightMax?: number;
    disabled?: boolean;
    hideLeft?: boolean;
    hideConvert?: boolean;
    hideRight?: boolean;
    onUpdate:(left: number, right: number, leftUpdate: boolean, rightUpdate: boolean) => void;
}

const DetailPair = (props: DetailPairProps) => {
    const {leftValue, leftCustomDisplay, rightCustomDisplay, rightMax, leftLabel, convertCost, rightValue, rightLabel, disabled, hideLeft, hideConvert, hideRight, onUpdate} = props;

    const adjustLeft = (value: number) => {
        onUpdate(Math.max(0,leftValue + value), rightValue, true, false);
    }

    const adjustRight = (value: number) => {
        onUpdate(leftValue, Math.max(0,rightValue + value), false, true);
    }

    const convertRight = () => {
        if(leftValue >= convertCost)
            onUpdate(leftValue-convertCost, rightValue+1, true, true);
    }

    const convertLeft = () => {
        onUpdate(leftValue+convertCost, rightValue-1, true, true);
    }

    return <>
        <Grid container>
            <Grid item xs={5}>
                {hideLeft !== true && <><Stack direction="row" spacing={1} display="flex" justifyContent="center" alignItems="center" lineHeight={1} height="80px">
                    <ButtonFlatIcon disabled={disabled || leftValue === 0} onClick={() => adjustLeft(-1)}><RemoveIcon /></ButtonFlatIcon>
                    <Hexagon>
                        <HexagonNumber><Box fontSize="80px"><HexagonIcon fontSize="inherit" color="primary" /></Box></HexagonNumber>
                        <HexagonNumber>{leftCustomDisplay !== undefined ? leftCustomDisplay(leftValue) : leftValue}</HexagonNumber>
                    </Hexagon>
                    <ButtonFlatIcon disabled={disabled} onClick={() => adjustLeft(+1)}><AddIcon /></ButtonFlatIcon>
                </Stack>
                <SideLabel>{leftLabel}</SideLabel></>}
            </Grid>
            <Grid item xs={2}>  
                {hideConvert !== true && <Stack color="primary" direction="row" spacing="1px" display="flex" justifyContent="center" alignItems="center" lineHeight={1} height="80px">
                    <Typography color="primary" fontWeight={700}>{-convertCost}</Typography>
                    <Hexagon>
                        <HexagonNumber onClick={convertRight}><Box fontSize="80px"><RightIcon fontSize="inherit" color={leftValue >= convertCost ? "primary" : "disabled"} /></Box></HexagonNumber>
                    </Hexagon>
                    <Typography color="primary" fontWeight={700}>+1</Typography>
                </Stack>}
            </Grid>

            <Grid item xs={5}>
                {hideRight !== true && <><Stack direction="row" spacing={1} display="flex" justifyContent="center" alignItems="center" lineHeight={1} height="80px">
                    <ButtonFlatIcon disabled={disabled || leftValue === 0} onClick={() => adjustRight(-1)}><RemoveIcon /></ButtonFlatIcon>
                    <Hexagon>
                        <HexagonNumber><Box fontSize="80px"><HexagonIcon fontSize="inherit" color="primary" /></Box></HexagonNumber>
                        <HexagonNumber>{rightCustomDisplay !== undefined ? rightCustomDisplay(rightValue) : rightValue}</HexagonNumber>
                    </Hexagon>
                    <ButtonFlatIcon disabled={disabled || (rightMax !== undefined && rightValue < rightMax)} onClick={() => adjustRight(+1)}><AddIcon /></ButtonFlatIcon>
                </Stack>
                <SideLabel>{rightLabel}</SideLabel></>}
            </Grid>
        </Grid>
    </>
}

export default DetailPair;