import { Outfit, OutfitBlock, PlayerCharacter } from "../../../model/character";

import { Box, Button, Chip, FormGroup, Stack, TextField, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { NumberField } from "../../../components/formFields/NumberField";
import { TabBar } from "../../../components/TabBar";
import AbilityEditor from "../AbilityEditor";
import { Modal, ModalContentSection } from "../../../components/Modal";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import SelectField from "../../../components/formFields/SelectField";

const defaultBlock: OutfitBlock = {
    name: "Outfit",
    slots: 0,
    armor: 0,
    armorAtZero: 0,
    defence: 0,
    endurance: 0,
    outfitType: [],
    addFeatures: [],
    removeFeatures: [],
    passives: [],
    strongHits: [],
    resources: 0,
    knowledge: 0,
    influence: 0,
    acquire: 0,
    notes: "",
    size: "standard"
}

const defaultOutfit: Outfit = {
    base: { ...defaultBlock },
    variations: [],
    modifications: [],
    id: "",
    description: "",
    isAcquired: false,
    isWorn: false,
    name: "New Outfit",
    isStashed: false,
}

export const GetNewOutfit = (): Outfit => {
    return {
        ...defaultOutfit,
        id: v4()
    }
}

const OutfitBlockEditor = (props: {outfitBlock: OutfitBlock, setOutfitBlock: (outfitBlock: OutfitBlock) => void}) => {
    const { outfitBlock, setOutfitBlock } = props;
    const [ feature, setFeature ] = useState("");

    const onChange = (key: string, value: any) => {
        setOutfitBlock({
            ...outfitBlock,
            [key]: value
        })
    }

    const handleAddFeature = () => {
        const newFeatures = [ ...outfitBlock.addFeatures, feature ];
        onChange("addFeatures", newFeatures);
        setFeature("");
    }

    const handleRemoveFeature = (index: number) => {
        const features = [ ...outfitBlock.addFeatures ];
        features.splice(index, 1);
        onChange("addFeatures", features);
    }

    return <>
        <Stack spacing={1}>
            <FormFieldLabel label="Name">
                <TextField fullWidth type="text" variant="standard" value={outfitBlock.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
            </FormFieldLabel>
            <SelectField value={outfitBlock.size} onChange={(value) => onChange("size", value)} options={[
                { name: "Size - Trinket (0.25)", value: "trinket" },
                { name: "Size - Small (1)", value: "small" } ,
                { name: "Size - Standard (2)", value: "standard" } ,
                { name: "Size - Large (3)", value: "large" } ,
            ]} />
            <FormFieldLabel label="Equipment Slots" justifyContent="end">
                <NumberField step={1} value={outfitBlock.slots} onChange={(value) => onChange("slots", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Armor" justifyContent="end">
                <NumberField step={1} value={outfitBlock.armor} onChange={(value) => onChange("armor", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Armor at 0 Endurance" justifyContent="end">
                <NumberField step={1} value={outfitBlock.armorAtZero} onChange={(value) => onChange("armorAtZero", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Defence" justifyContent="end">
                <NumberField step={1} value={outfitBlock.defence} onChange={(value) => onChange("defence", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Endurance" justifyContent="end">
                <NumberField step={1} value={outfitBlock.endurance} onChange={(value) => onChange("endurance", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Acquire" justifyContent="end">
                <NumberField step={1} value={outfitBlock.acquire} onChange={(value) => onChange("acquire", value)} customRenderer={(v) => `${v}t`} />
            </FormFieldLabel>
            <FormFieldLabel label="Influence" justifyContent="end">
                <NumberField step={1} value={outfitBlock.influence} onChange={(value) => onChange("influence", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Resources" justifyContent="end">
                <NumberField step={1} value={outfitBlock.resources} onChange={(value) => onChange("resources", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Knowledge" justifyContent="end">
                <NumberField step={1} value={outfitBlock.knowledge} onChange={(value) => onChange("knowledge", value)}  customRenderer={(v) => `${v}kn`} />
            </FormFieldLabel>
        </Stack>
        <ModalContentSection title="Keywords">
            <Stack direction="row">
                <TextField sx={{flexGrow: 1}} type="text" variant="standard" value={feature} onChange={(event) => setFeature(event.currentTarget.value)} />
                <Button onClick={handleAddFeature}>Add Feature</Button>
            </Stack>
            <Stack flexWrap="wrap" useFlexGap direction="row" spacing={1}>
                {outfitBlock.addFeatures.map((value, index) => <Chip onClick={() => handleRemoveFeature(index)} label={value} key={index} />)}
            </Stack>
        </ModalContentSection>
        <AbilityEditor title="Passive Abilities" abilities={outfitBlock.passives} setAbilities={(value) => onChange("passives", value)} />
        <Box paddingLeft={1}>
            <Typography>Notes</Typography>
            <TextField value={outfitBlock.notes} onChange={(event) => onChange('notes', event.currentTarget.value)} multiline fullWidth />
        </Box>
        <AbilityEditor title="Strong Hit Options" abilities={outfitBlock.strongHits} setAbilities={(value) => onChange("strongHits", value)} />
    </>
}

export const OutfitEditor = (props: {
    setOutfit: (value: Outfit) => void,
    onClose: () => void,
    outfit?: Outfit;
    character: PlayerCharacter,
}) => {
    const { outfit, character, setOutfit, onClose } = props;
    const [ current, setCurrent ] = useState<Outfit>(defaultOutfit);

    useEffect(() => {
        if(outfit !== undefined)
            setCurrent(outfit ?? defaultOutfit);
    }, [outfit]);


    const handleModalCancel = () => {
        onClose();
    }

    const handleModalSubmit = () => {
        setOutfit(current)
    }

    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }

    const handleAddVariation = () => {
        const variations = [ ...current.variations, { ...defaultBlock, name: "New Variation" } ]
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleRemoveVariation = (index: number) => {
        const variations = [ ...current.variations ];
        variations.splice(index,1);
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleChangeVariation = (index: number, variation: OutfitBlock) => {
        const variations = [ ...current.variations ];
        variations[index] = variation;
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleAddModification = () => {
        const modifications = [ ...current.modifications, { ...defaultBlock, name: "New Modification" } ]
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const handleRemoveModification = (index: number) => {
        const modifications = [ ...current.modifications ];
        modifications.splice(index,1);
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const handleChangeModification = (index: number, modification: OutfitBlock) => {
        const modifications = [ ...current.modifications ];
        modifications[index] = modification;
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const warnSlots = character.outfits.filter(w => w.isWorn).length;
    
    return <>
        <Modal
            title={current.name}
            open={outfit !== undefined}
            onClose={handleModalCancel}
            actions={[
                {
                    label: "Cancel",    
                    onClick: handleModalCancel,
                },
                {
                    label: "Save",    
                    onClick: handleModalSubmit,
                    primary: true,
                },
            ]}
        >
            <FormGroup>
                <FormFieldLabel label="Name">
                    <TextField type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
                </FormFieldLabel>
                <Box paddingLeft={1}>
                    <Typography>Description</Typography>
                    <TextField value={current.description} onChange={(event) => onChange('description', event.currentTarget.value)} multiline fullWidth />
                </Box>
                <TabBar pages={[
                    { label: "Base", content: <OutfitBlockEditor outfitBlock={current.base} setOutfitBlock={(wb) => onChange("base", wb)} /> },
                    { label: "Variations", content: <>
                        {current.variations.map((variation, index) => {
                            return <div key={index}>
                                <OutfitBlockEditor outfitBlock={variation} setOutfitBlock={(wb) => handleChangeVariation(index, wb)} />
                                <Button onClick={() => handleRemoveVariation(index)}>Remove {variation.name}</Button>
                            </div>
                        })}
                        <Button onClick={handleAddVariation}>Add Variation</Button>
                    </> },
                    { label: "Mods", content: <>
                        {current.modifications.map((modification, index) => {
                            return <div key={index}>
                                <OutfitBlockEditor outfitBlock={modification} setOutfitBlock={(wb) => handleChangeModification(index, wb)} />
                                <Button onClick={() => handleRemoveModification(index)}>Remove {modification.name}</Button>
                            </div>
                        })}
                        <Button onClick={handleAddModification}>Add Modification</Button>
                    </> },
                ]} />
            </FormGroup>
        </Modal>
    </>
}